@import 'styles/import.scss';

.imageWrapper {
  margin: 0 -40px;
  position: relative;
  @include media-breakpoint-up(md) {
    margin: 0;
    @include make-col(6);
    border-right: 1px solid $grey25;
    > span {
      height: 100% !important;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.factsWrapper {
  margin: 0 -40px;
  @include media-breakpoint-up(md) {
    @include make-col(6);
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.factsContainer {
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  background: $grey1;
  height: 100%;
  @include media-breakpoint-up(lg) {
    padding: 120px;
  }
}

.factTitle {
  @include heading-5;
  margin-bottom: 8px;
  color: $blue_dark;
}

.factText {
  @include text-sm;
  color: $blue_dark;
}

.imageFactsWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}
